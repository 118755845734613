@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  color: inherit;
  font-size: inherit;
  scroll-behavior: smooth;
}

body {
  line-height: 1.8em;
  font-weight: 400;
  font-size: 16px;
}

a {
  text-decoration: none;
}
